<template>
  <Modal v-bind="$attrs" :show-close-button="false">
    <div>
      <div class="p-8">
        <h3 class="mb-4 text-2xl" :class="{ 'text-center': centered }">
          {{ title }}
        </h3>
        <slot>
          <p class="mb-4 whitespace-pre-wrap text-black/54">{{ message }}</p>
        </slot>
        <div class="flex w-full flex-wrap justify-center gap-4 md:flex-row">
          <Button
            v-if="showCancelButton"
            :color="cancelButtonColor"
            :class="cancelButtonClasses"
            :link="cancelButtonLink"
            :target="cancelButtonLinkTarget"
            @click="emit('cancel')"
          >
            {{ cancelButtonText }}
          </Button>
          <Button
            :color="confirmButtonColor"
            :class="
              confirmButtonClasses +
              (showConfirmButtonFirst ? ' order-first' : '')
            "
            :loading="confirmButtonLoading"
            :link="confirmButtonLink"
            :target="confirmButtonLinkTarget"
            @click="emit('confirm')"
          >
            {{ confirmButtonText }}
          </Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
defineProps({
  title: { type: String, default: '' },
  message: { type: String, default: '' },
  showCancelButton: { type: Boolean, default: true },
  cancelButtonText: { type: String, default: '' },
  cancelButtonColor: { type: String, default: 'secondary' },
  cancelButtonClasses: {
    type: String,
    default: 'ml-0 flex-1 whitespace-nowrap',
  },
  cancelButtonLink: { type: String, default: '' },
  cancelButtonLinkTarget: { type: String, default: '' },
  showConfirmButtonFirst: { type: Boolean, default: false },
  confirmButtonLoading: { type: Boolean, default: false },
  confirmButtonText: { type: String, default: '' },
  confirmButtonColor: { type: String, default: 'primary' },
  confirmButtonClasses: { type: String, default: 'flex-1 whitespace-nowrap' },
  confirmButtonLink: { type: String, default: '' },
  confirmButtonLinkTarget: { type: String, default: '' },
  centered: { type: Boolean, default: false },
});

const emit = defineEmits(['cancel', 'confirm']);
</script>
